import React, { useState } from 'react';
import Bio from '../components/Bio';
import SEO from '../components/SEO';





export default function AboutPage() {
  
  return (
    <>
    <SEO />
      <Bio />
    </>
  )
}